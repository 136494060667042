nav.navbar {
	@include color-scheme(dark) {
		border-color: $gray-800 !important;
		background-color: $gray-800 !important;
		color: $gray-200;
	}
}

.navbar-brand img {
	@include color-scheme(dark) {
		filter: brightness(0) invert(0.7);
	}
}

.dropdown-menu {
	@include color-scheme(dark) {
		background-color: $gray-700;
		.dropdown-item {
			color: $gray-200;
			&:hover {
				background-color: $gray-600;
			}
		}
		.dropdown-header {
			color: $gray-200;
		}
	}
}

nav.nav, .checklist-menu-dropdown {
	button.btn, button.dropdown-toggle, button.dropdown-toggle.btn-light {
		@include color-scheme(dark) {
			background-color: $gray-700;
			color: $gray-300;
			border-color: $gray-700;
		}
	}

	i {
		@include color-scheme(dark) {
			background-color: $gray-700;
			color: $gray-300;
		}
	}

	.dropdown-menu {
		@include color-scheme(dark) {
			background-color: $gray-700;
			.dropdown-item {
				color: $gray-200;
				&:hover {
					background-color: $gray-600;
				}
			}
			.dropdown-header {
				color: $gray-200;
			}
		}
	}
	.navigation-menu {
		@extend .ms-2;

		.dropdown-toggle {
			padding: 0.375rem;
			line-height: 1;
			&::after {
				display: none;
			}
		}

		.dropdown-item {
			font-size: 0.9rem;
		}
	}
}
