// tabs

@include color-scheme(dark) {
	.nav-tabs {
		border-bottom: 1px solid $gray-700;
	}

    .nav-tabs .nav-link {
        color: $gray-500;
        background-color: $gray-900;
        // border-color: $gray-700;
        border-color: transparent;
        margin-right: 1px;
    }

    .nav-tabs .nav-link.active {
        border-color: transparent transparent $gray-800;
        background-color: $gray-800;
        color: $gray-500;
    }

    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
        background-color: $gray-800;
        
        border-color: transparent;
        isolation: isolate;
    }

}
