$header-height: 52px;
$checklist-container-padding: 10px;
$checklist-container-height: $header-height + 2 * $checklist-container-padding;

$dark-bg: $black;
$dark-content-bg: $gray-800;
$dark-item-text: $gray-200;
$dark-item-anchor: lightblue;
$dark-major-heading: #92999f;
$dark-sub-heading: #92999f;

$title-color: #2f5c84;

.checklist-page {
	height: calc(100vh - #{$header-height});
	padding: $checklist-container-padding;

	background-color: $gray-200;
	@include color-scheme(dark) {
		background-color: $dark-bg;
	}
}

.group-checklist-container {
	column-gap: 10px;
	justify-content: center !important;
}

.checklist-container {
	padding: 15px 20px 20px;
	box-shadow: 0 0 6px rgb(0 0 0 / 10%);
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - #{$checklist-container-height});
	flex: 1 1 550px;
	max-width: 700px;

	&.is-mobile {
		max-width: 100%;
		height: calc(100vh - #{$checklist-container-height} - 42px);
	}

	background-color: white;
	@include color-scheme(dark) {
		background-color: $dark-content-bg;
	}

	.checklist-title {
		font-size: 1.05rem;
		margin-bottom: 0;
		padding-left: 4px;
		color: $title-color;
		@include color-scheme(dark) {
			color: $dark-major-heading;
		}
	}

	.checklist-items {
		flex: 1 1 auto;
		margin-top: 0;
		margin-bottom: auto;
		overflow-y: scroll;

		h6 {
			@include color-scheme(dark) {
				color: $dark-major-heading;
			}
		}
	}

	.checklist-input {
		margin-top: auto;
		margin-bottom: 0;
		padding-top: 15px;
	}

	.list-group.choose-checklist {
		background-color: #f1f8ff;
		.list-group-item {
			background-color: #f1f8ff;
			color: #3a6fa3;
			padding: 0.4rem 0.75rem;
			font-size: 0.9rem;
			&:hover {
				background-color: $gray-200;
			}
		}
		@include color-scheme(dark) {
			background-color: $gray-700;
			.list-group-item {
				background-color: $gray-700;
				color: $gray-200;
				&:hover {
					background-color: $gray-600;
				}
			}
		}
	}
}

.checklist-header {
	margin-bottom: 5px;
	form {
		margin-right: 3px;
	}
	form div.input-group {
		padding-top: 0 !important;
	}
	button.btn-light {
		@include color-scheme(dark) {
			background-color: $gray-700;
			color: $gray-300;
			border-color: $gray-700;
		}
	}

	i {
		@include color-scheme(dark) {
			background-color: $gray-700;
			color: $gray-300;
		}
	}

	ul.dropdown-menu {
		@include color-scheme(dark) {
			background-color: $gray-700;
			.dropdown-item {
				color: $gray-200;
				&:hover {
					background-color: $gray-600;
				}
			}
			.dropdown-header {
				color: $gray-200;
			}
		}
	}

	.checklist-menu-dropdown {
		.dropdown-toggle {
			&::after {
				display: none;
			}
		}

		.dropdown-item {
			font-size: 0.8rem;
		}
	}

	.react-datepicker__navigation {
		top: 10px;
	}

	.react-datepicker__year .react-datepicker__year-text {
		display: inline-block;
		width: 3rem;
		margin: 3px auto;
	}
	@include color-scheme(dark) {
		.react-datepicker {
			background: $gray-800;
		}
		.react-datepicker__header {
			background-color: $gray-700;
			color: $gray-300;
		}

		.react-datepicker__current-month {
			color: $gray-300;
		}

		.react-datepicker__day-name,
		.react-datepicker__day,
		.react-datepicker__month,
		.react-datepicker__year {
			color: $gray-300;
		}

		.react-datepicker__day:hover,
		.react-datepicker__month-text:hover,
		.react-datepicker__quarter-text:hover,
		.react-datepicker__year-text:hover {
			background-color: $gray-600;
		}
	}
}

.checklist-item {
	padding: 3px 4px;
	font-family: Roboto, sans-serif;
	border: none;

	@include color-scheme(dark) {
		background-color: $dark-content-bg;
	}

	&:hover {
		cursor: pointer;
		background-color: $gray-200;

		@include color-scheme(dark) {
			background-color: $gray-700;
		}
	}

	.checklist-input {
		padding: 0;
	}

	&.section {
		h1 {
			border-bottom: 1px solid $gray-200;
			color: #777;
			@include color-scheme(dark) {
				border-color: $gray-600;
				color: $dark-major-heading;
			}
		}
	}

	.checklist-text {
		flex: 1;
	}

	h1,
	h2,
	h3 {
		margin-bottom: 0;
	}

	h1 {
		font-size: 0.95rem;
		color: $title-color;
		font-weight: 400;
		margin: 0;
		padding-top: 10px;
		padding-bottom: 3px;

		strong {
			font-size: 0.8rem;
		}
	}

	h2 {
		font-weight: 600;
		font-size: 0.8rem;
		margin: 1px 0;
		padding-top: 5px;
		text-transform: uppercase;
		color: $title-color;
		@include color-scheme(dark) {
			color: $dark-sub-heading;
		}
	}
	p,
	a {
		font-size: 0.8rem;
		font-weight: 400;
		margin-bottom: 0;
		color: #333333;
		@include color-scheme(dark) {
			color: $dark-item-text;
		}
	}
	a {
		font-weight: 500;
		color: #3d87c7;
		@include color-scheme(dark) {
			color: $dark-item-text;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&.complete {
		background: #d7eeff;
		color: #3f89bd;

		@include color-scheme(dark) {
			background: #0f4d7c;
			color: #a8dbff;
		}

		.checklist-text {
			text-decoration: line-through;

			> * {
				color: #333333;
			}

			a {
				color: #3d87c7;
			}
			@include color-scheme(dark) {
				> * {
					color: #a8dbff;
				}

				a {
					color: #4ba0eb;
				}
			}
		}
	}

	&.in-progress {
		background: #ecfbec;
		color: #32571a;

		@include color-scheme(dark) {
			background: #32571a;
			color: #ecfbec;
		}

		.checklist-text {
			> * {
				color: #333333;
			}

			a {
				color: #32571a;
			}
			@include color-scheme(dark) {
				> * {
					color: #c4dfc4;
				}

				a {
					color: #81bd5a;
				}
			}
		}
	}

	&.failed {
		background: #ffe2e2;
		color: #804b4b;

		@include color-scheme(dark) {
			background: #804b4b;
			color: #ffe2e2;
		}

		.checklist-text {
			> * {
				color: #804b4b;
			}

			a {
				color: #7c2424;
			}

			@include color-scheme(dark) {
				> * {
					color: #f5d6d6;
				}

				a {
					color: #ff7676;
				}
			}
		}
	}

	button.status-button {
		line-height: normal;
		color: #3d87c7;
		@include color-scheme(dark) {
			color: #7ea5c7;
		}

		.bi-square {
			color: $gray-500;
		}

		.bi-arrow-right-square-fill {
			color: #379227;
			@include color-scheme(dark) {
				color: #8ab183;
			}
		}

		.bi-x-square-fill {
			color: #ca0000;
			@include color-scheme(dark) {
				color: #cf8e8e;
			}
		}
	}

	.checklist-item-menu {
		.dropdown-toggle {
			@include color-scheme(dark) {
				color: $gray-200;
				background: $gray-600;
				:hover {
					color: #c9e3fa;
					background: #3d87c7;
				}
			}
			&::after {
				display: none;
			}
			line-height: normal;
			color: darkgrey;
			background: lightgrey;
			font-size: 0.8rem !important;
			border-radius: 2px;
			padding: 0;
			:hover {
				color: #c9e3fa;
				background: #3d87c7;
			}
			i {
				padding: 0 1px !important;
			}
		}
		.dropdown-item {
			font-size: 0.8rem !important;
		}
	}

	button.item-action {
		line-height: normal;
		color: darkgrey;
		background: lightgrey;
		font-size: 0.8rem !important;
		border-radius: 2px;
		:hover {
			color: #c9e3fa;
			background: #3d87c7;
		}

		@include color-scheme(dark) {
			color: $gray-200;
			background: $gray-600;
			:hover {
				color: #c9e3fa;
				background: #3d87c7;
			}
		}
		i {
			padding-left: 2px;
			padding-right: 2px;
		}

		&.trash-button {
			:hover {
				color: darkred;
				background: pink;
			}

			@include color-scheme(dark) {
				color: $gray-200;
				background: $gray-600;
				:hover {
					color: pink;
					background: darkred;
				}
			}
		}
	}
}

.checklist-input-container {
	flex: 0 0;
	margin-top: auto;
	margin-bottom: 0px;
}

.checklist-input {
	input,
	input::placeholder,
	input:focus-visible {
		@include color-scheme(dark) {
			background: $gray-600;
			color: $gray-200;
		}
	}
	input {
		@include color-scheme(dark) {
			border-color: $gray-600;
		}
	}

	> * {
		font-size: 0.8rem;
	}
}

.checklist-item-tag {
	color: #115b83;
	padding: 1px 4px;
	background: #cfe8ff;
	border-radius: 6px;
	font-size: 0.7rem;

	@include color-scheme(dark) {
		color: $gray-200;
		background: #2a5174;
	}
}

.checklist-item-duration {
	color: #154b00;
	padding: 1px 4px;
	background: #caebc7;
	border-radius: 6px;
	font-size: 0.7rem;

	@include color-scheme(dark) {
		color: $gray-200;
		background: rgb(100, 65, 0);
	}
}
