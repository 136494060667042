// Change bootstrap variables here
$blue: #3d87c7;

// Importing Bootstrap
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600&display=swap');

// Import Pages
@import '/src/pages/pages';

// Importing Layout
@import '/src/features/navigation/navigation';
@import '/src/features/authentication/authentication';

// Importing Components
@import '/src/features/checklist/checklist.scss';
@import "react-datepicker/dist/react-datepicker.css";
@import "/src/features/timer/timer.scss";

$header-height: 52px;

div.main {
	margin-top: $header-height;
}

.btn-primary {
	color: #fff;
	&:hover,
	&:focus {
		color: #fff;
	}
}
