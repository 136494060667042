$btn-close-color: $black;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");

$dark-bg: $black;
$dark-content-bg: $gray-800;
$dark-item-text: $gray-200;
$dark-item-anchor: lightblue;
$dark-major-heading: #92999f;
$dark-sub-heading: #92999f;

$title-color: #2f5c84;

#timer-modal {
	.modal-header {
		background-color: $gray-200;

		@include color-scheme(dark) {
			background-color: $dark-content-bg;
			color: $dark-item-text;
			.btn-close {
				filter: $btn-close-white-filter;
			}
		}
	}
	.modal-body {
		background-color: $gray-200;
		@include color-scheme(dark) {
			background-color: $dark-content-bg;
		}
	}

	.timer-circle-outer {
		background: linear-gradient(220deg, #89b5e1, #15486c);
		width: fit-content;

		@include color-scheme(dark) {
			background: linear-gradient(220deg, #a7a7a7, #343434);
		}
	}

	.timer-circle-inner {
		background: linear-gradient(45deg, #89b5e1, #15486c);
		@include color-scheme(dark) {
			background: linear-gradient(45deg, #a7a7a7, #343434);
		}
	}

	.rest-circle {
        .main {
            stop-color: rgb(6, 173, 0);
        }
        .alt {
            stop-color: rgb(105, 222, 103);
        }
		@include color-scheme(dark) {
			.main {
				stop-color: red;
			}
			.alt {
				stop-color: pink;
			}
		}
	}

	.work-circle {
        .main {
            stop-color: #f0c60a;
        }
        .alt {
            stop-color: #ffb300;
        }
		@include color-scheme(dark) {
			.main {
				stop-color: #1a4f70;
			}
			.alt {
				stop-color: #3085bc;
			}
		}
	}

    .form-select {

        @include color-scheme(dark) {
            background-color: $dark-content-bg;
            color: $dark-item-text;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            border-color: $gray-600;
        }

    }
}
